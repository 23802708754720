import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiSecure } from "../../components/api";
import { Loading, LoadingItem } from "../../components/Loading/Loading";
import "./Project.css";

import {
  FilePdfOutlined,
  MenuOutlined,
  FileOutlined,
  MergeCellsOutlined,
  PlusOutlined,
  DownOutlined,
  UpOutlined,
  LeftOutlined,
  RightOutlined,
  EditOutlined,
  NumberOutlined,
  SortDescendingOutlined
} from "@ant-design/icons";
import Icon from '@mdi/react';
import { mdiCheckboxMarkedOutline, mdiCropSquare, mdiPlayBoxOutline, mdiTruckDeliveryOutline } from '@mdi/js';

import { DatePicker, Dropdown, Menu } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getParsedDate, getParsedTime, getParsedTimeNoZero } from "../../components/globalFunctions";
import { PdfButton } from "../../components/PdfButton/PdfButton";
import {
  ProgressBar,
  ProgressBarMulti
} from "../../components/ProgressBar/ProgressBar";
import { useTranslate } from "../../components/Translate";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import moment, { isMoment } from "moment";


import { getPdf } from "../../components/PdfButton/PdfButton";
import { NewStage } from "./NewStage";
import { copy, DraggableRowItem, move, RenderListFooter, RenderListHeader, reorder } from "./ProjectDraggable";
import { EditStageData } from "./EditStageData";
import { VerifyDelete } from "./VerifyDelete";
import { Header } from '../App/App';
import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";
import { getEnvSettings } from "../../config/config";





const BoxHeader = ({ useDragging, handleShowDragging, projectData, listType, setListType, enableEditing }) => {
  const { locale } = useTranslate();
  const [pdfLoading, setPdfLoading] = useState(false);
  const envConfig = getEnvSettings();
  const { lang } = useSelector((state) => state.currentUser.user);

  return <div className="project-box-header">
    <div>
      <p className="project-box-name font-dm-bold">
        {locale.production}
      </p>

      <p className="project-box-label font-dm-medium">
        {listType == "elementit" ? locale.elementgroups : ''}
        {listType == "etapit" ? locale.etaps : ''}
        {listType == "aikataulu" ? locale.schedule : ''}
      </p>
    </div>

    <p className="project-box-right font-dm-bold">




      <div style={{ marginRight: '1em', cursor: 'pointer' }} onClick={async () => {
        console.log('ASDASDASDASDASASD')
        if (pdfLoading) return
        setPdfLoading(true)

        let resp = await getPdf(
          listType == "elementit"
            ? `${envConfig.apiUrlPrefix}getProjectGroupsPdf`
            : `${envConfig.apiUrlPrefix}getProjectStagesPdf`,
          {
            projectId: projectData.tiedot.Tyokohdenro,
            localeId: lang,
          }
        )
        console.log('PDFresp', resp)
        setPdfLoading(false)


      }
      }>

        {pdfLoading ? <LoadingItem color="#000" /> : <FileOutlined style={{ fontSize: 17 }} />}

      </div>




      <div style={{ marginRight: '1em' }}>


        <Dropdown
          className="lngDropdown"
          menu={{
            items: [


              {
                key: 'showdragging', label: (
                  <p
                    className="menuValue font-dm-medium"
                    onClick={() => {
                      if (enableEditing) return
                      handleShowDragging()
                    }}
                  >
                    {useDragging ? locale.etapcontrolhide : locale.etapcontrol}
                  </p>
                ),
                disabled: enableEditing
              }


            ]
          }
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <SortDescendingOutlined style={{ fontSize: 17, marginLeft: 10, marginRight: 5 }} />
        </Dropdown>


      </div>

      <Dropdown
        className="lngDropdown"
        menu={{
          items: [


            listType !== "elementit" ?
              {
                key: 'elementti', label: (
                  <p
                    className="menuValue font-dm-medium"
                    onClick={() => {
                      setListType("elementit")
                    }}
                  >
                    {locale.elementgroups}
                  </p>
                ),
              } : undefined,


            listType !== "etapit" ?
              {
                key: 'etappi',
                label: (
                  <p
                    className="menuValue font-dm-medium"
                    onClick={() => {
                      setListType("etapit")
                    }}
                  >
                    {locale.etaps}
                  </p>
                ),
              } : undefined,


            listType !== "aikataulu" ?

              {
                key: 'aikataulu', label: (
                  <p
                    className="menuValue font-dm-medium"
                    onClick={() => {
                      setListType("aikataulu")
                    }}
                  >
                    {locale.schedule}
                  </p>
                ),
              } : undefined
          ]
        }
        }
        trigger={["click"]}
        placement="bottomRight"
      >
        <MenuOutlined style={{ fontSize: 17, marginLeft: 10, marginRight: 5 }} />
      </Dropdown>
    </p>
  </div>

}


export const ProjectElements = ({ navigate, projectData, animation, listType, setListType, enableEditing, itemsInDroppable, useDragging, handleShowDragging }) => {
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();

  const isMobile = useMediaQuery({ maxWidth: 500 });
  const isMediumWidth = useMediaQuery({ maxWidth: 1100 });

  const [elementListOpen, setElementListOpen] = useState({});
  const [etappiListOpen, setEtappiListOpen] = useState({});
  const [aikatauluListOpen, setAikatauluListOpen] = useState({});

  const { tehtaat, lang, selectedFactory } = useSelector((state) => state.currentUser.user);



  const [noEtappiTotal, setNoEtappiTotal] = useState({});
  const [etappiTotal, setEtappiTotal] = useState([]);
  const [parentEtappiTotal, setParentEtappiTotal] = useState([]);


  const [tableFilter, setTableFilter] = useState("tunnus");
  const [sortDirection, setSortDirection] = useState("asc");

  const [aikatauluData, setAikatauluData] = useState([]);
  const [noAikatauluData, setNoAikatauluData] = useState([]);


  // Lasketaan etappien elementtien yhteisarvot
  useEffect(() => {
    let tmp = {
      total: 0,
      m2: 0,
      paino: 0,
      piirustus: 0,
      valmistus: 0,
      toimitus: 0,
    }

    let noEtapValues = JSON.parse(JSON.stringify(tmp))
    let EtapValueArray = []

    projectData?.elementit?.map((itm, idx) => {
      if (itm.Etappi) {
        if (!EtapValueArray[itm.Etappi]) {
          EtapValueArray[itm.Etappi] = JSON.parse(JSON.stringify(tmp))
        }
        EtapValueArray[itm.Etappi].total += 1
        EtapValueArray[itm.Etappi].m2 += itm.M2
        EtapValueArray[itm.Etappi].paino += itm.Paino
        EtapValueArray[itm.Etappi].piirustus += itm.Piirustus ? 1 : 0
        EtapValueArray[itm.Etappi].valmistus += itm.ValmistusPVM ? 1 : 0
        EtapValueArray[itm.Etappi].toimitus += itm.ToimitusPVM ? 1 : 0
      } else {
        noEtapValues.total += 1
        noEtapValues.m2 += itm.M2
        noEtapValues.paino += itm.Paino
        noEtapValues.piirustus += itm.Piirustus ? 1 : 0
        noEtapValues.valmistus += itm.ValmistusPVM ? 1 : 0
        noEtapValues.toimitus += itm.ToimitusPVM ? 1 : 0

      }
    })



    //Lasketaan juurietapin arvot
    let parentEtapTotal = []
    projectData?.elementit?.map((itm, idx) => {
      if (itm.Etappi) {

        let etapId

        let etapData = projectData.etapit.find(etap => etap.EtappiNro === itm.Etappi)

        if (etapData?.EtappiNro) etapId = etapData?.EtappiNro
        if (etapData?.ParentID) etapId = etapData?.ParentID

        if (!etapId) return

        if (!parentEtapTotal[etapId]) {
          parentEtapTotal[etapId] = JSON.parse(JSON.stringify(tmp))
        }

        parentEtapTotal[etapId].total += 1
        parentEtapTotal[etapId].m2 += itm.M2
        parentEtapTotal[etapId].paino += itm.Paino
        parentEtapTotal[etapId].piirustus += itm.Piirustus ? 1 : 0
        parentEtapTotal[etapId].valmistus += itm.ValmistusPVM ? 1 : 0
        parentEtapTotal[etapId].toimitus += itm.ToimitusPVM ? 1 : 0
      }
    })



    setNoEtappiTotal(noEtapValues)
    setEtappiTotal(EtapValueArray)

    setParentEtappiTotal(parentEtapTotal)
  }, [projectData]);





  // Lasketaan aikataulu elementtien yhteisarvot
  useEffect(() => {
    let tmp = {
      total: 0,
      m2: 0,
      paino: 0,
      piirustus: 0,
      valmistus: 0,
      toimitus: 0,
      elements: []
    }

    let noAikatauluArr = JSON.parse(JSON.stringify(tmp))
    let aikatauluArr = {}

    projectData?.elementit?.map((itm, idx) => {
      if (itm.ArvioituToimitusPvm) {
        let ndate = getParsedDate(itm.ArvioituToimitusPvm)

        if (!aikatauluArr[ndate]) {
          aikatauluArr[ndate] = JSON.parse(JSON.stringify(tmp))
        }

        aikatauluArr[ndate].total += 1
        aikatauluArr[ndate].m2 += itm.M2
        aikatauluArr[ndate].paino += itm.Paino
        aikatauluArr[ndate].piirustus += itm.Piirustus ? 1 : 0
        aikatauluArr[ndate].valmistus += itm.ValmistusPVM ? 1 : 0
        aikatauluArr[ndate].toimitus += itm.ToimitusPVM ? 1 : 0

        aikatauluArr[ndate].elements.push(itm)
      } else {
        noAikatauluArr.total += 1
        noAikatauluArr.m2 += itm.M2
        noAikatauluArr.paino += itm.Paino
        noAikatauluArr.piirustus += itm.Piirustus ? 1 : 0
        noAikatauluArr.valmistus += itm.ValmistusPVM ? 1 : 0
        noAikatauluArr.toimitus += itm.ToimitusPVM ? 1 : 0
        noAikatauluArr.elements.push(itm)
      }

    })


    console.log('test4 aikatauluArr, ', aikatauluArr)
    console.log('test4 noAikatauluArr, ', noAikatauluArr)

    setAikatauluData(aikatauluArr)
    setNoAikatauluData(noAikatauluArr)
  }, [projectData]);




  if (!projectData.tiedot) {
    return (
      <>
        <p>Ei tietoa</p>
        <button onClick={() => navigate(-1)}>Go back</button>
      </>
    );
  }

  const handleOpenElement = (id) => {
    let OpenList = elementListOpen;
    let IsOpen = OpenList[id];

    OpenList[id] = !IsOpen;

    setElementListOpen({
      ...OpenList,
    });
  };

  const handleOpenEtappi = (id) => {
    let OpenList = etappiListOpen;
    let IsOpen = OpenList[id];

    OpenList[id] = !IsOpen;

    setEtappiListOpen({
      ...OpenList,
    });
  };

  const handleOpenAikataulu = (id) => {
    let OpenList = aikatauluListOpen;
    let IsOpen = OpenList[id];

    OpenList[id] = !IsOpen;

    setAikatauluListOpen({
      ...OpenList,
    });
  };





  const sortTableItems = (items) => {
    let nItems = [...items]
    console.log('ITEMSTEST', nItems)

    const sortValue = (key) => {
      nItems.sort((a, b) => {
        const valueA = a[key];
        const valueB = b[key];

        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      });
    }

    const sortDate = (key) => {
      nItems.sort((a, b) => {
        const dateA = new Date(a[key]);
        const dateB = new Date(b[key]);

        if (isNaN(dateA) && isNaN(dateB)) {
          return 0; // If neither has a valid date, maintain original order
        }

        if (isNaN(dateA)) {
          return 1; // a should come after b if a's date is invalid
        }

        if (isNaN(dateB)) {
          return -1; // a should come before b if b's date is invalid
        }

        // Both dates are valid, sort them
        return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
      });
    };



    const sortString = (key, secondaryKey = null) => {
      nItems.sort((a, b) => {
        const valueA = a[key];
        const valueB = b[key];


        const comparison = sortDirection === 'asc' ? valueA?.localeCompare(valueB) : valueB?.localeCompare(valueA);

        if (comparison !== 0 || !secondaryKey) {
          return comparison;
        }

        // If primary keys are equal, sort by secondary key
        const secondaryValueA = a[secondaryKey];
        const secondaryValueB = b[secondaryKey];
        return secondaryValueA - secondaryValueB
      });
    };

    if (tableFilter === "tunnus") sortString("Tunnus", "ValmistusNro")
    if (tableFilter === "id") sortValue("ValmistusNro")
    if (tableFilter === "pituus") sortValue("Pituus")
    if (tableFilter === "korkeus") sortValue("Korkeus")
    if (tableFilter === "paksuus") sortValue("Paksuus")
    if (tableFilter === "m2") sortValue("M2")
    if (tableFilter === "paino") sortValue("Paino")
    if (tableFilter === "piirustus") sortDate("Piirustus")
    if (tableFilter === "valmistus") sortDate("ValmistusPVM")
    if (tableFilter === "toimitus") sortDate("ToimitusPVM")

    if (tableFilter === "lastcol") {
      if (listType === "elementit") sortString("EtappiNimi")
      if (listType === "etapit") sortString("ValmistusTehdas")
      if (listType === "aikataulu") sortValue("KuormakirjaNumero")
    }

    return nItems
  }




  return (<div className={`projectpage-content ${animation}`}>

    {/* Elementtiryhmät */}
    {listType == "elementit" && (
      <div className="project-box">

        <BoxHeader enableEditing={enableEditing} useDragging={useDragging} handleShowDragging={handleShowDragging} projectData={projectData} listType={listType} setListType={setListType} />

        <ProgressBar
          style={{ marginLeft: isMobile ? 0 : 0, marginRight: isMobile ? 0 : 0, marginBottom: 10 }}
          backgroundColor={"#14DC40"}
          data1={projectData.tiedot.TilattuKplYht}
          data2={projectData.tiedot.ValmistettuKplYht}
        />

        {projectData.elementtiryhmat.length >= 1 &&
          projectData.elementtiryhmat.map((item, index) => {
            console.log('TEST', item)
            let isVälitys = false;

            if (
              tehtaat[item.Tehdas] &&
              tehtaat[item.Tehdas].name == "Välitys"
            ) {
              isVälitys = true;
            }

            return (
                <div
                  key={item.ElementtiRyhmaNro}
                  className="project-p-item"
                >
                  <div
                    className="project-p-header"
                    onClick={() => {
                      if (!isVälitys)
                        handleOpenElement(item.ElementtiRyhmaNro);
                    }}
                  >

                    <div className="item-title">
                      <p
                        className={`name ${isVälitys ? "font-dm-regular" : "font-dm-bold"
                          }`}
                      >
                        {item.ElementtiRyhma}
                      </p>

                      <div className="item-footer">
                        <p className="factory font-dm-regular">
                          {tehtaat[item.Tehdas] &&
                            tehtaat[item.Tehdas].name}
                        </p>
                        {isMobile ?
                          <div className="item-numbers">
                            <p className="number font-dm-regular">
                              {item.TilattuKpl || 0} <Icon path={mdiCropSquare} size={0.8} style={{ marginLeft: 2 }} />
                            </p>
                            <p className="number font-dm-regular">
                              {item.PiirustuksiaKpl || 0} <Icon path={mdiPlayBoxOutline} size={0.8} style={{ marginLeft: 2 }} />

                            </p>

                            <p className="number font-dm-regular">
                              {item.ValmistettuKpl || 0} <Icon path={mdiCheckboxMarkedOutline} size={0.8} style={{ marginLeft: 2 }} />
                            </p>

                            <p className="number font-dm-regular">
                              {item.ToimitettuKpl || 0} <Icon path={mdiTruckDeliveryOutline} size={0.8} style={{ marginLeft: 2 }} />
                            </p>
                          </div>
                          : <></>}
                      </div>
                    </div>


                    {!isMobile ? <>
                      <div className="item-labelvalue">
                        <p className="label font-dm-regular">
                          {locale.ordered}
                        </p>
                        <p
                          className={`value ${isVälitys
                            ? "font-dm-regular"
                            : "font-dm-bold"
                            }`}
                        >
                          {item.TilattuKpl || 0}
                        </p>
                      </div>

                      <div className="item-labelvalue">
                        <p className="label font-dm-regular">
                          {locale.blueprints}
                        </p>
                        <p
                          className={`value ${isVälitys
                            ? "font-dm-regular"
                            : "font-dm-bold"
                            }`}
                        >
                          {item.PiirustuksiaKpl || 0}
                        </p>
                      </div>

                      <div className="item-labelvalue">
                        {!isVälitys && (
                          <>
                            <p className="label font-dm-regular">
                              {locale.prepared}
                            </p>
                            <p className="value font-dm-bold">
                              {item.ValmistettuKpl || 0}
                            </p>
                          </>
                        )}
                      </div>

                      <div className="item-labelvalue">
                        {!isVälitys && (
                          <>
                            <p className="label font-dm-regular">
                              {locale.booked}
                            </p>
                            <p className="value font-dm-bold">
                              {item.ToimitettuKpl || 0}
                            </p>
                          </>
                        )}
                      </div>
                    </>
                      : <></>}


                    <div className="item-arrow">
                      {elementListOpen[item.ElementtiRyhmaNro]
                        ? <UpOutlined className="item-arrow-icon" style={{}} />
                        : <RightOutlined className="item-arrow-icon" style={{}} />
                      }
                    </div>
                  </div>

                  {elementListOpen[item.ElementtiRyhmaNro] && (
                    <>
                      <div className="project-p-table">

                        <RenderListHeader
                          isMobile={isMobile}
                          isMediumWidth={isMediumWidth}
                          listType={listType}
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          sortDirection={sortDirection}
                          setSortDirection={setSortDirection}
                        />


                        <Droppable
                          droppableId={
                            "GROUP_" + item.ElementtiRyhmaNro
                          }
                          isDropDisabled={true}
                        >
                          {(provided, snapshot) => {
                            /* console.log("snapshot", snapshot); */

                            return (
                              <div
                                ref={provided.innerRef}
                                onClickCapture={() => {
                                  //console.log("TESTASD");
                                }}
                              >
                                {item.data &&
                                  item.data.length >= 1 &&
                                  [...sortTableItems(item.data)].map((item2, index2) => {
                                    return (
                                      <DraggableRowItem
                                      key={item2.ValmistusNro}
                                        index={index2}
                                        itemId={item2.ValmistusNro}
                                        item={item2}
                                        listType={listType}
                                        dragDisabled={
                                          !useDragging || !enableEditing
                                        }
                                        itemsInDroppable={
                                          itemsInDroppable
                                        }
                                        isMobile={isMobile}
                                        isMediumWidth={isMediumWidth}
                                      />
                                    );
                                  })}

                                <div style={{ display: "none" }}>
                                  {provided.placeholder}
                                </div>
                              </div>
                            );
                          }}
                        </Droppable>

                        <RenderListFooter
                          isMobile={isMobile}
                          isMediumWidth={isMediumWidth}
                          total={item?.data?.length}
                          m2={item.Neliot}
                          paino={item.Tonnit}
                          piirustus={item.PiirustuksiaKpl}
                          valmistus={item.ValmistettuKpl}
                          toimitus={item.ToimitettuKpl}
                        />
                      </div>
                    </>
                  )}
                </div>
            );
          })}
      </div>
    )}




    {/* Etapit */}
    {listType == "etapit" && (
      <>
        <div className="project-box">

          <BoxHeader enableEditing={enableEditing} useDragging={useDragging} handleShowDragging={handleShowDragging} projectData={projectData} listType={listType} setListType={setListType} />

          {projectData.etapit.map((etappiBase, indx) => {
            console.log('test2', etappiBase)
            if (etappiBase.ParentID != 0) return;
            let etapityhteenveto = projectData.etapityhteenveto;
            let yhtData = etapityhteenveto.find(
              (obj) => obj.EtappiNro === etappiBase.EtappiNro
            );

            return (
              <>
                <div key={etappiBase.EtappiNro} className="project-p-item">

                  <div
                    className="project-p-header"
                    onClick={() => {
                      handleOpenEtappi(etappiBase.EtappiNro);
                    }}
                  >
                    {/*                       <div className="item-title">
                              <p className={`name font-dm-bold`}>
                                {etappiBase.Etappi}
                              </p>
                              <p className="factory font-dm-regular">
                                {(etappiBase?.ArvioituToimituksenAlku || etappiBase?.ArvioituToimituksenLoppu) &&
                                  (etappiBase?.ArvioituToimituksenAlku
                                    ? getParsedDate(
                                      etappiBase?.ArvioituToimituksenAlku
                                    )
                                    : "") +
                                  " - " +
                                  (etappiBase?.ArvioituToimituksenLoppu
                                    ? getParsedDate(
                                      etappiBase?.ArvioituToimituksenLoppu
                                    )
                                    : "")
                                }
                              </p>
                            </div> */}

                    <div className="item-title">
                      <p
                        className={`name font-dm-bold`}
                      >
                        {etappiBase.Etappi}
                      </p>

                      <div className="item-footer">


                        {!isMobile &&
                          <p className="factory font-dm-regular" style={{ fontSize: 13, alignSelf: 'center' }}>
                            {(etappiBase?.ArvioituToimituksenAlku || etappiBase?.ArvioituToimituksenLoppu) &&
                              (etappiBase?.ArvioituToimituksenAlku
                                ? getParsedDate(
                                  etappiBase?.ArvioituToimituksenAlku
                                )
                                : "") +
                              " - " +
                              (etappiBase?.ArvioituToimituksenLoppu
                                ? getParsedDate(
                                  etappiBase?.ArvioituToimituksenLoppu
                                )
                                : "")
                            }
                          </p>
                        }
                        {isMobile ?
                          <div className="item-numbers">
                            <p className="number font-dm-regular">
                              {parentEtappiTotal[etappiBase.EtappiNro]?.total || 0} <Icon path={mdiCropSquare} size={0.8} style={{ marginLeft: 2 }} />
                            </p>
                            <p className="number font-dm-regular">
                              {parentEtappiTotal[etappiBase.EtappiNro]?.piirustus || 0} <Icon path={mdiPlayBoxOutline} size={0.8} style={{ marginLeft: 2 }} />

                            </p>

                            <p className="number font-dm-regular">
                              {parentEtappiTotal[etappiBase.EtappiNro]?.valmistus || 0} <Icon path={mdiCheckboxMarkedOutline} size={0.8} style={{ marginLeft: 2 }} />
                            </p>

                            <p className="number font-dm-regular">
                              {parentEtappiTotal[etappiBase.EtappiNro]?.toimitus || 0} <Icon path={mdiTruckDeliveryOutline} size={0.8} style={{ marginLeft: 2 }} />
                            </p>
                          </div>
                          : <></>}
                      </div>
                    </div>



                    {!isMobile ? <>


                      <div className="item-labelvalue">
                        <p className="label font-dm-regular">
                          {locale.ordered}
                        </p>
                        <p
                          className={`value font-dm-bold`}
                        >
                          {parentEtappiTotal[etappiBase.EtappiNro]?.total || 0}
                        </p>
                      </div>

                      <div className="item-labelvalue">
                        <p className="label font-dm-regular">
                          {locale.blueprints}
                        </p>
                        <p
                          className={`value font-dm-bold`}
                        >
                          {parentEtappiTotal[etappiBase.EtappiNro]?.piirustus || 0}
                        </p>
                      </div>

                      <div className="item-labelvalue">

                        <p className="label font-dm-regular">
                          {locale.prepared}
                        </p>
                        <p className="value font-dm-bold">
                          {parentEtappiTotal[etappiBase.EtappiNro]?.valmistus || 0}
                        </p>

                      </div>

                      <div className="item-labelvalue">
                        <p className="label font-dm-regular">
                          {locale.booked}
                        </p>
                        <p className="value font-dm-bold">
                          {parentEtappiTotal[etappiBase.EtappiNro]?.toimitus || 0}
                        </p>
                      </div>
                    </>
                      : <></>}
                  </div>


                  {etappiListOpen[etappiBase.EtappiNro] && (
                    <>

                      <div className="dividerTest"></div>

                      <ProgressBar
                        style={{ marginLeft: isMobile ? 0 : 10, marginRight: isMobile ? 0 : 10, marginBottom: 10 }}
                        backgroundColor={"#14DC40"}
                        data1={parentEtappiTotal[etappiBase.EtappiNro]?.total || 0}
                        data2={parentEtappiTotal[etappiBase.EtappiNro]?.valmistus || 0}
                      />

                      <ProgressBar
                        style={{ marginLeft: isMobile ? 0 : 10, marginRight: isMobile ? 0 : 10, marginBottom: 10 }}
                        backgroundColor={"#2B4BF2"}
                        data1={parentEtappiTotal[etappiBase.EtappiNro]?.total || 0}
                        data2={parentEtappiTotal[etappiBase.EtappiNro]?.toimitus || 0}
                      />


                      {projectData.etapit.map((etappiItem, indx2) => {
                        //console.log('createetap', etappiItem.Etappi, etappiBase.EtappiNro, etappiItem.ParentID)
                        if ((etappiBase.EtappiNro != etappiItem.EtappiNro) && (etappiBase.EtappiNro != etappiItem.ParentID)) return;

                        //if (etappiBase.EtappiNro != etappiItem.EtappiNro) return
                        return (

                          <div key={etappiItem.EtappiNro}>

                            {etappiBase.EtappiNro === etappiItem.ParentID ?

                              <p className="project-box-name font-dm-bold" style={{ marginBottom: 0 }}>
                                {etappiItem.Etappi}
                              </p>
                              : <></>}



                            <div className="project-p-table">
                              <RenderListHeader
                                isMobile={isMobile}
                                isMediumWidth={isMediumWidth}
                                listType={listType}
                                tableFilter={tableFilter}
                                setTableFilter={setTableFilter}
                                sortDirection={sortDirection}
                                setSortDirection={setSortDirection}
                              />

                              <Droppable
                                droppableId={
                                  "GROUP_" + etappiItem.EtappiNro
                                }
                                isDropDisabled={true}
                              >
                                {(provided, snapshot) => {
                                  /* console.log("snapshot", snapshot); */
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      onClickCapture={() => {
                                        // console.log("TESTASD");
                                      }}
                                    >
                                      {projectData.elementit &&
                                        projectData.elementit.length >=
                                        1 &&
                                        [...sortTableItems(projectData.elementit)].map(
                                          (item2, index2) => {
                                            if (
                                              item2.Etappi !=
                                              etappiItem.EtappiNro
                                            )
                                              return;
                                            return (
                                              <DraggableRowItem
                                              key={item2.ValmistusNro}
                                                index={index2}
                                                itemId={
                                                  item2.ValmistusNro
                                                }
                                                item={item2}
                                                listType={listType}
                                                dragDisabled={
                                                  !useDragging ||
                                                  !enableEditing
                                                }
                                                itemsInDroppable={
                                                  itemsInDroppable
                                                }
                                                isMobile={isMobile}
                                                isMediumWidth={isMediumWidth}
                                              />
                                            );
                                          }
                                        )}

                                      <div style={{ display: "none" }}>
                                        {provided.placeholder}
                                      </div>
                                    </div>
                                  );
                                }}
                              </Droppable>

                              <RenderListFooter
                                isMobile={isMobile}
                                isMediumWidth={isMediumWidth}
                                total={etappiTotal[etappiItem.EtappiNro]?.total}
                                m2={etappiTotal[etappiItem.EtappiNro]?.m2}
                                paino={etappiTotal[etappiItem.EtappiNro]?.paino}
                                piirustus={etappiTotal[etappiItem.EtappiNro]?.piirustus}
                                valmistus={etappiTotal[etappiItem.EtappiNro]?.valmistus}
                                toimitus={etappiTotal[etappiItem.EtappiNro]?.toimitus}
                              />

                            </div>
                          </div>

                        )
                      })}
                    </>
                  )}

                </div>
              </>
            )
          })}
        </div>


        {/* ELEMENTIT JOILlA EI OLE VALITTUA ETAPPIA */}
        {projectData?.elementit?.filter(itm2 => itm2.Etappi === null).length >= 1 && (
          <div className="project-box">
            <p className="project-box-name font-dm-bold">
              {locale.nophases}
            </p>

            {true && (
              <>
                <div className="project-p-table">
                  <RenderListHeader
                    isMobile={isMobile}
                    isMediumWidth={isMediumWidth}
                    listType={listType}
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                  />
                  <Droppable
                    droppableId={"GROUP_" + "OTHERS"}
                    isDropDisabled={true}
                  >
                    {(provided, snapshot) => {
                      /* console.log("snapshot", snapshot); */
                      return (
                        <div
                          ref={provided.innerRef}
                          onClickCapture={() => {
                            // console.log("TESTASD");
                          }}
                        >
                          {projectData.elementit &&
                            projectData.elementit.length >= 1 &&
                            [...sortTableItems(projectData.elementit)].map(
                              (item2, index2) => {
                                if (item2.Etappi) return;

                                return (
                                  <DraggableRowItem
                                  key={item2.ValmistusNro}
                                    index={index2}
                                    itemId={item2.ValmistusNro}
                                    item={item2}
                                    listType={listType}
                                    dragDisabled={
                                      !useDragging || !enableEditing
                                    }
                                    itemsInDroppable={itemsInDroppable}
                                    isMobile={isMobile}
                                    isMediumWidth={isMediumWidth}
                                  />
                                );
                              }
                            )}

                          <div style={{ display: "none" }}>
                            {provided.placeholder}
                          </div>
                        </div>
                      );
                    }}
                  </Droppable>

                  <RenderListFooter
                    isMobile={isMobile}
                    isMediumWidth={isMediumWidth}
                    total={noEtappiTotal?.total}
                    m2={noEtappiTotal?.m2}
                    paino={noEtappiTotal?.paino}
                    piirustus={noEtappiTotal?.piirustus}
                    valmistus={noEtappiTotal?.valmistus}
                    toimitus={noEtappiTotal?.toimitus}
                  />

                </div>
              </>
            )}
          </div>
        )}
      </>
    )}







    {/* AIKATAULU NÄKYMÄ */}
    {listType == "aikataulu" && (
      <>
        <div className="project-box">
          <BoxHeader enableEditing={enableEditing} useDragging={useDragging} handleShowDragging={handleShowDragging} projectData={projectData} listType={listType} setListType={setListType} />


          {Object.keys(aikatauluData).map((dateKey, indx) => {
            const dateData = aikatauluData[dateKey]

            return (
              <>
                <div key={indx} className="project-p-item">

                  <div
                    className="project-p-header"
                    onClick={() => {
                      handleOpenAikataulu(dateKey);
                    }}
                  >
                    <div className="item-title">
                      <p className={`name font-dm-bold`}>
                        {dateKey}
                      </p>



                      {isMobile ?
                        <div className="item-numbers">
                          <p className="number font-dm-regular">
                            {dateData?.total || 0} <Icon path={mdiCropSquare} size={0.8} style={{ marginLeft: 2 }} />
                          </p>
                          <p className="number font-dm-regular">
                            {dateData?.piirustus || 0} <Icon path={mdiPlayBoxOutline} size={0.8} style={{ marginLeft: 2 }} />

                          </p>

                          <p className="number font-dm-regular">
                            {dateData?.valmistus || 0}<Icon path={mdiCheckboxMarkedOutline} size={0.8} style={{ marginLeft: 2 }} />
                          </p>

                          <p className="number font-dm-regular">
                            {dateData?.toimitus || 0} <Icon path={mdiTruckDeliveryOutline} size={0.8} style={{ marginLeft: 2 }} />
                          </p>
                        </div>
                        : <></>}


                    </div>


                    {!isMobile ? <>

                      <div className="item-labelvalue">
                        <p className="label font-dm-regular">
                          {locale.ordered}
                        </p>
                        <p
                          className={`value font-dm-bold`}
                        >
                          {dateData?.total || 0}
                        </p>
                      </div>

                      <div className="item-labelvalue">
                        <p className="label font-dm-regular">
                          {locale.blueprints}
                        </p>
                        <p
                          className={`value font-dm-bold`}
                        >
                          {dateData?.piirustus || 0}
                        </p>
                      </div>

                      <div className="item-labelvalue">

                        <p className="label font-dm-regular">
                          {locale.prepared}
                        </p>
                        <p className="value font-dm-bold">
                          {dateData?.valmistus || 0}
                        </p>

                      </div>

                      <div className="item-labelvalue">
                        <p className="label font-dm-regular">
                          {locale.booked}
                        </p>
                        <p className="value font-dm-bold">
                          {dateData?.toimitus || 0}
                        </p>
                      </div>
                    </> : <></>}
                  </div>


                  {aikatauluListOpen[dateKey] && (
                    <>

                      <div className="dividerTest"></div>

                      <ProgressBar
                        style={{ marginLeft: isMobile ? 0 : 10, marginRight: isMobile ? 0 : 10, marginBottom: 10 }}

                        backgroundColor={"#14DC40"}
                        data1={dateData?.total || 0}
                        data2={dateData?.valmistus || 0}
                      />

                      <ProgressBar
                        style={{ marginLeft: isMobile ? 0 : 10, marginRight: isMobile ? 0 : 10, marginBottom: 10 }}

                        backgroundColor={"#2B4BF2"}
                        data1={dateData?.total || 0}
                        data2={dateData?.toimitus || 0}
                      />



                      <div className="project-p-table">
                        <RenderListHeader
                          isMobile={isMobile}
                          isMediumWidth={isMediumWidth}
                          listType={listType}
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          sortDirection={sortDirection}
                          setSortDirection={setSortDirection}
                        />

                        <Droppable
                          droppableId={
                            "GROUP_" + dateKey
                          }
                          isDropDisabled={true}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                onClickCapture={() => {
                                }}
                              >
                                {dateData.elements &&
                                  dateData.elements?.length >=
                                  1 &&
                                  [...sortTableItems(dateData.elements)].map(
                                    (item2, index2) => {
                                      return (
                                        <DraggableRowItem
                                        key={item2.ValmistusNro}
                                          index={index2}
                                          itemId={
                                            item2.ValmistusNro
                                          }
                                          item={item2}
                                          listType={listType}
                                          dragDisabled={
                                            !useDragging ||
                                            !enableEditing
                                          }
                                          itemsInDroppable={
                                            itemsInDroppable
                                          }
                                          isMobile={isMobile}
                                          isMediumWidth={isMediumWidth}
                                        />
                                      );
                                    }
                                  )}

                                <div style={{ display: "none" }}>
                                  {provided.placeholder}
                                </div>
                              </div>
                            );
                          }}
                        </Droppable>

                        <RenderListFooter
                          isMobile={isMobile}
                          isMediumWidth={isMediumWidth}
                          total={dateData?.total}
                          m2={dateData?.m2}
                          paino={dateData?.paino}
                          piirustus={dateData?.piirustus}
                          valmistus={dateData?.valmistus}
                          toimitus={dateData?.toimitus}
                        />

                      </div>


                    </>
                  )}

                </div>
              </>
            )
          })}
        </div>


        {/* ELEMENTIT JOILlA EI OLE aikataulua */}
        {noAikatauluData.elements.length >= 1 && (
          <div className="project-box">
            <p className="project-box-name font-dm-bold">
              {locale.noscheduletext}
            </p>

            {true && (
              <>
                <div className="project-p-table">
                  <RenderListHeader
                    isMobile={isMobile}
                    isMediumWidth={isMediumWidth}
                    listType={listType}
                    tableFilter={tableFilter}
                    setTableFilter={setTableFilter}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                  />
                  <Droppable
                    droppableId={"GROUP_" + "OTHERS"}
                    isDropDisabled={true}
                  >
                    {(provided, snapshot) => {
                      /* console.log("snapshot", snapshot); */
                      return (
                        <div
                          ref={provided.innerRef}
                          onClickCapture={() => {
                            // console.log("TESTASD");
                          }}
                        >
                          {noAikatauluData.elements &&
                            noAikatauluData.elements.length >= 1 &&
                            [...sortTableItems(noAikatauluData.elements)].map(
                              (item2, index2) => {

                                return (
                                  <DraggableRowItem
                                  key={item2.ValmistusNro}
                                    index={index2}
                                    itemId={item2.ValmistusNro}
                                    item={item2}
                                    listType={listType}
                                    dragDisabled={
                                      !useDragging || !enableEditing
                                    }
                                    itemsInDroppable={itemsInDroppable}
                                    isMobile={isMobile}
                                    isMediumWidth={isMediumWidth}
                                  />
                                );
                              }
                            )}

                          <div style={{ display: "none" }}>
                            {provided.placeholder}
                          </div>
                        </div>
                      );
                    }}
                  </Droppable>

                  <RenderListFooter
                    isMobile={isMobile}
                    isMediumWidth={isMediumWidth}
                    total={noEtappiTotal?.total}
                    m2={noEtappiTotal?.m2}
                    paino={noEtappiTotal?.paino}
                    piirustus={noEtappiTotal?.piirustus}
                    valmistus={noEtappiTotal?.valmistus}
                    toimitus={noEtappiTotal?.toimitus}
                  />

                </div>
              </>
            )}
          </div>
        )}
      </>
    )}












    {/* TOIMITUS BOXI */}
    {listType == "elementit" && (
      <div className="project-box">
        <p className="project-box-name font-dm-bold">{locale.deliveryheader}</p>

        <ProgressBar
          style={{ marginLeft: isMobile ? 0 : 0, marginRight: isMobile ? 0 : 0, marginBottom: 10 }}

          backgroundColor={"#2B4BF2"}
          data1={projectData.tiedot.ValmistettuKplYht}
          data2={projectData.tiedot.ToimitettuKplYht}
        />

        {projectData.kuormakirja.length >= 1 &&
          projectData.kuormakirja.map((item, index) => {


            let hour = getParsedTime(item.PurkuKlo)
            let classColor

            if (item.Lastaaja) {
              if (item.LastausAlku && item.LastausLoppu) {
                classColor = "listTrue "
              } else {
                classColor = "listHalf "
              }
            } else {
              classColor = "listFalse "
            }

            return (
              <>
                <div
                  key={item.KuormakirjaNumero}
                  className="project-p-item"
                >
                  <div
                    className="project-p-header"
                    onClick={() => {
                      /* handleOpenElement(item.ElementtiRyhmaNro); */
                    }}
                  >
                    <div className="item-title">
                      <p className={`name font-dm-bold ${classColor}`}>
                        {item.KuormakirjaNumero}
                      </p>

                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <p className="factory font-dm-regular">
                          {tehtaat[item.Tehdas] &&
                            tehtaat[item.Tehdas].name}
                        </p>

                        {!isMobile ? <>
                          <p className="factory font-dm-regular oneLine">
                            {item.AutonNimi}
                          </p>

                          {item.SuunniteltuLastausaika && !isMediumWidth && (
                            <div className="oneLine">
                              <p className={"factory font-dm-regular"}>
                                Lastaus:
                              </p>
                              <p className={"factory font-dm-regular"}>
                                {getParsedDate(item.SuunniteltuLastausaika)}
                              </p>
                              <p className={"factory font-dm-regular"}>
                                {getParsedTimeNoZero(item.SuunniteltuLastausaika)}
                              </p>
                            </div>
                          )}
                        </> : <></>}
                      </div>



                    </div>

                    <div className="item-labelvalue">
                      <p className={`value font-dm-bold ${classColor}`}>
                        {item?.Maara || 0}
                      </p>
                      <p className={`label font-dm-regular ${classColor}`}>kpl</p>
                    </div>

                    <div className="item-labelvalue">
                      <p className={`value font-dm-bold ${classColor}`}>
                        {item?.ElementtienPaino.toFixed(1) || 0}
                      </p>
                      <p className={`label font-dm-regular ${classColor}`}>t</p>
                    </div>

                    <div className="item-labelvalue">
                      <p className={`value font-dm-bold ${classColor}`}>
                        {getParsedDate(item?.ToimitusPVM) || 0}
                      </p>
                    </div>


                    {!isMobile ? <>
                      <div className="item-labelvalue">
                        <p className={`value font-dm-bold ${classColor}`}>
                          {hour}
                        </p>
                      </div>
                    </> : <></>}

                    <div className="item-pdf">
                      <PdfButton
                        url={`${envConfig.apiUrlPrefix}getDeliveryPdf`}
                        data={{
                          deliveryId: item.KuormakirjaNumero,
                          projectId: projectData.tiedot.Tyokohdenro,
                          useSqlLocale: false,
                          localeId: lang,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      </div>
    )}
  </div>

  );
};
