import { useCallback, useEffect, useState } from "react";
import { apiSecure } from "../../components/api";
import { Loading } from "../../components/Loading/Loading";
import "./MapPage.css";

import { MenuFoldOutlined, MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "../../components/Translate";
import { useDispatch, useSelector } from "react-redux";
import config, { getEnvSettings } from "../../config/config";


import { AdvancedMarker, APIProvider, ControlPosition, InfoWindow, Map, MapControl, Marker, Pin, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { Dropdown } from "antd";
import { useMediaQuery } from "react-responsive";


export const RenderMapInfo = ({ infoWindowShown, selectedMarker, handleClose }) => {

  const clickItem = (id) => {
    window.open(`${window.location.origin}/project/${id}`)
  }


  return (<>
    {infoWindowShown && selectedMarker && (
      <InfoWindow
        position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
        onClose={handleClose}
        headerContent={<div className="map-info-header">
          <p className="font-dm-bold">{selectedMarker.isProject ? `${selectedMarker.id} ${selectedMarker.name}` : ''}{selectedMarker.isFactory ? 'Tehdas' : ''}</p>
        </div>}
      >
        {selectedMarker.isProject ? <>
          {/*      <p className="map-info-data font-dm-medium">{selectedMarker.id} {selectedMarker.name}</p> */}
          <p className="map-info-data font-dm-medium">Tila: {selectedMarker?.status}</p>

          <button className="map-info-btn font-dm-medium clickable" onClick={() => {
            clickItem(selectedMarker?.id)
          }}>
            Avaa työkohde
          </button>
        </> : <></>}

        {selectedMarker.isFactory ? <>
        </> : <></>}
      </InfoWindow>
    )}

  </>)

}



const RenderFilters = ({ filterActive, setFilterActive, archiveYearsMenu, archiveFilter, setArchiveFilter }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1000 });


  const onClickArchiveFilter = ({ key }) => {
    console.log(`Click on item ${key}`);
    setArchiveFilter(key)
  };


  const onClickMobileMenu = ({ key }) => {
    console.log(`Click on item ${key}`);

    if (["1", "2", "3"].includes(key)) {
      setFilterActive(key)
    } else {
      setFilterActive("5")
      setArchiveFilter(key)
    }
  };

  const mobileMenu = [
    {
      label: (
        <p className="map-mobile-selector-item font-dm-medium">Tuotanto</p>
      ),
      key: "3",
    },
    {
      label: (
        <p className="map-mobile-selector-item font-dm-medium">Tarjouspyyntö</p>
      ),
      key: "1",
    },
    {
      label: (
        <p className="map-mobile-selector-item font-dm-medium">Tarjoukset</p>
      ),
      key: "2",
    },

    {
      label: (
          <span className="map-mobile-selector-item font-dm-medium">Arkisto</span>
      ),
      key: "5",
      children: archiveYearsMenu
    }
  ]


  return (<MapControl position={ControlPosition.BLOCK_START_INLINE_CENTER}>
    <div className="map-selector">

      {!isTabletOrMobile ? (
        <div className="map-selector-group">
          <div
            className={`map-selector-item font-dm-medium ${filterActive == "3" && "active"}`}
            onClick={() => setFilterActive("3")}
          >
            <p className="map-selector-label">Tuotanto</p>
          </div>
          <div
            className={`map-selector-item font-dm-medium ${filterActive == "1" && "active"
              }`}
            onClick={() => setFilterActive("1")}
          >
            <p className="map-selector-label">Tarjouspyyntö</p>
          </div>
          <div
            className={`map-selector-item font-dm-medium ${filterActive == "2" && "active"
              }`}
            onClick={() => setFilterActive("2")}
          >
            <p className="map-selector-label">Tarjoukset</p>
          </div>

          <Dropdown menu={{
            items: archiveYearsMenu,
            selectable: true,
            defaultSelectedKeys: ['all'],
            onClick: onClickArchiveFilter
          }}
            trigger={['click']}
            getPopupContainer={() => document.querySelector('.gm-style')} // Määritä kartan sisäinen elementti
          >
            <div
              className={`map-selector-item font-dm-medium ${filterActive == "5" && "active"
                }`}
              onClick={() => setFilterActive("5")}
            >
              <p className="map-selector-label">Arkisto</p>
              <p className="map-selector-label-2">({archiveFilter === 'all' ? 'Kaikki' : archiveFilter})</p>
            </div>
          </Dropdown>
        </div>)
        : (<Dropdown
          menu={{
            items: mobileMenu,
            selectable: true,
            defaultSelectedKeys: ['3'],
            onClick: onClickMobileMenu
          }}
          trigger={["click"]}
          open={menuVisible}
          onOpenChange={(visible) => {
            setMenuVisible(visible);
          }}
          placement="bottomRight"
          className="map-mobile-selector"
          getPopupContainer={() => document.querySelector('.gm-style')} // Määritä kartan sisäinen elementti
        >
          {menuVisible ? (
            <MenuFoldOutlined className="header-dropdown" style={{ color: '#333' }} />
          ) : (
            <MenuOutlined className="header-dropdown" style={{ color: '#333' }} />
          )}
        </Dropdown>)}
    </div>
  </MapControl>)
}

export const MapPage = ({ setToken }) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);

  const { locale } = useTranslate();
  const navigate = useNavigate();
  const envConfig = getEnvSettings();


  const [filterActive, setFilterActive] = useState("3");

  const API_KEY = process.env.REACT_APP_MAPS_API_KEY //config.devMapsKey

  const [factoryData, setFactoryData] = useState([]);
  const [worksData, setWorksData] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoWindowShown, setInfoWindowShown] = useState(false);

  const [archiveYears, setArchiveYears] = useState([]);
  const [archiveFilter, setArchiveFilter] = useState("all");
  const [archiveYearsMenu, setArchiveYearsMenu] = useState([]);

  const [filteredProjects, setFilteredProjects] = useState([]);

  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);


  // clicking a marker will set it as selected and show the infowindow
  const handleMarkerClick = useCallback((markerData) => {
    setSelectedMarker(markerData);
    setInfoWindowShown(true);
  }, []);

  // if the maps api closes the infowindow, we have to synchronize our state
  const handleCloseInfo = useCallback(() => {
    setInfoWindowShown(false);
    setSelectedMarker(null);
  }, []);



  const getMapProjects = () => {
    setisLoading(true);
    console.log("getMapProjects");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getMapProjects`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        setArchiveYears(response?.data?.data?.arkistoVuodet);
        setWorksData(response?.data?.data?.kohteet);
        setFactoryData(response?.data?.data?.tehtaat);

        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getMapProjects();
  }, []);


  useEffect(() => {
    if (archiveYears?.length <= 0) return

    let nArr = [
      {
        key: 'all',
        label: 'Kaikki',
      },
    ]

    archiveYears.map((item, index) => {
      let yearString = item.ArkistoVuosi.toString()

      nArr.push({
        key: yearString,
        label: yearString,
      })
    });

    setArchiveYearsMenu(nArr)
  }, [archiveYears]);



  useEffect(() => {
    console.log("worksData", worksData);

    if (worksData.length >= 1) {
      let nArr = []

      if (filterActive == "kaikki") {
        nArr = worksData
      }

      if (filterActive == "1") nArr = worksData.filter(itm => itm.Tila === 1)
      if (filterActive == "2") nArr = worksData.filter(itm => itm.Tila === 2)
      if (filterActive == "3") nArr = worksData.filter(itm => itm.Tila === 3)

      if (filterActive == "5") {
        nArr = worksData.filter(itm => {
          // Suodatetaan "Tila" kentän perusteella
          const matchesTila = itm.Tila === 5;

          // Jos archiveFilter ei ole "all", suodatetaan myös "ValmistumisVuosi" perusteella
          const matchesArchiveFilter = archiveFilter === "all" || itm.ValmistumisVuosi === parseInt(archiveFilter);

          // Palautetaan vain ne, jotka täyttävät molemmat ehdot
          return matchesTila && matchesArchiveFilter;
        });
      }

      setFilteredProjects(nArr)
    }
  }, [filterActive, worksData, archiveFilter]);


  if (isLoading) {
    return <Loading />;
  }




  return (
    <div className="map-page-main">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="map-page-inner">
          <APIProvider apiKey={API_KEY} onLoad={() => {
                    setGoogleMapsLoaded(true)
                  }}>
            <Map
              mapId={"a5c9eab71d5a0001"}
              style={{ width: "100%", height: "100%", borderRadius: "14px" }}
              defaultCenter={{ lat: 66.67, lng: 26.86 }} //Suomi
              defaultZoom={5}
              gestureHandling={"greedy"}
              disableDefaultUI={false}
              streetViewControl={false}
            >

              <RenderFilters filterActive={filterActive} setFilterActive={setFilterActive} archiveFilter={archiveFilter} setArchiveFilter={setArchiveFilter} archiveYearsMenu={archiveYearsMenu} />

              {filteredProjects.map((item) => {
                if (!item.lat || !item.lng) return null;

                var newlat = parseFloat(item.lat.replace(/,/g, "."));
                var newlng = parseFloat(item.lng.replace(/,/g, "."));

                let color = item?.Varikoodi ? item?.Varikoodi : '#333'
                let color2 = color

                return (
                  <AdvancedMarker
                    key={item.Tyokohdenro}
                    position={{ lat: newlat, lng: newlng }}
                    onClick={() => handleMarkerClick({
                      isProject: true,
                      name: item.Nimi,
                      id: item.Tyokohdenro,
                      status: item?.TilaNimi,
                      lat: newlat,
                      lng: newlng,
                    })}
                  >
                    <Pin
                      background={color}
                      borderColor={"#333"}
                      glyphColor={color2}
                      scale={0.8}
                    />
                  </AdvancedMarker>
                );
              })}

              {factoryData.map((item) => {
                var newlat = parseFloat(item.lat.replace(/,/g, "."));
                var newlng = parseFloat(item.lng.replace(/,/g, "."));

                return (
                  <Marker
                    key={item.Id}
                    position={{ lat: newlat, lng: newlng }}
                    icon={{
                      url: item?.IkoniUrl,
                      scaledSize: window.google ? new window.google.maps.Size(30, 30) : null
                    }}
                  />
                )
              })}


              <RenderMapInfo infoWindowShown={infoWindowShown} selectedMarker={selectedMarker} handleClose={handleCloseInfo} />
            </Map>
          </APIProvider>
        </div>

      </div>

    </div>
  );
};
