import { useEffect, useRef, useState } from "react";
import { apiSecure } from "../../components/api";
import { LoadingItem } from "../../components/Loading/Loading";
import "./Project.css";

import { useTranslate } from "../../components/Translate";


import { useMediaQuery } from "react-responsive";
import { getEnvSettings } from "../../config/config";

import Icon, { CloseOutlined, DeleteOutlined, UploadOutlined, UsergroupAddOutlined, CheckSquareOutlined } from '@ant-design/icons';

import { getParsedDate } from "../../components/globalFunctions";
import { Input, Modal, notification, Tooltip } from "antd";
import { RichEditor } from "../../components/RichEditor/RichEditor";
import { useNavigate, useParams } from "react-router-dom";


const SavedSvg = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1647_258)">
      <path d="M10.9375 6.5625C10.6944 4.11414 8.99609 2.625 7 2.625C5.11328 2.625 3.89813 3.94297 3.5 5.25C1.85938 5.41406 0.4375 6.44191 0.4375 8.3125C0.4375 10.1172 1.91406 11.375 3.71875 11.375H10.8281C12.332 11.375 13.5625 10.6247 13.5625 8.96875C13.5625 7.33305 12.1133 6.62375 10.9375 6.5625Z" stroke="#2BF263" stroke-width="0.9" stroke-linejoin="round" />
      <path d="M8.66797 5.68457L5.72031 9.18457L4.45703 7.78457" stroke="#2BF263" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1647_258">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const SavedIcon = (props) => <Icon component={SavedSvg} {...props} />;

const UnSavedSvg = () => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.25 11.249L2.75 0.749023" stroke="#BA3434" stroke-width="0.9" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M13.792 9.32687C14.2683 8.93203 14.5625 8.33895 14.5625 7.5293C14.5625 5.89359 13.1133 5.1843 11.9375 5.12305C11.6944 2.67469 9.99609 1.18555 8 1.18555C7.28469 1.18555 6.6659 1.37504 6.15156 1.68156M3.56266 4.00879C2.35324 4.41348 1.4375 5.37187 1.4375 6.87305C1.4375 8.67773 2.91406 9.93555 4.71875 9.93555H9.76012L3.56266 4.00879Z" stroke="#BA3434" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
const UnSavedIcon = (props) => <Icon component={UnSavedSvg} {...props} />;


const SaveSvg = () => (

  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.375 10.7744H11.6016C13.2129 10.7744 14.5312 9.91865 14.5312 8.32519C14.5312 6.73174 12.9785 5.93838 11.7188 5.87598C11.4583 3.38398 9.63867 1.86816 7.5 1.86816C5.47852 1.86816 4.17656 3.20967 3.75 4.54004C1.99219 4.70703 0.46875 5.82559 0.46875 7.65723C0.46875 9.48887 2.05078 10.7744 3.98438 10.7744H5.625" stroke="#1620EB" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.375 7.49609L7.5 5.62109L5.625 7.49609M7.5 13.1334V6.08984" stroke="#1620EB" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

);
const SaveIcon = (props) => <Icon component={SaveSvg} {...props} />;


const { TextArea } = Input;


function formatSizeInMB(bytes) {
  const megabytes = bytes / (1024 * 1024);
  const formattedSize = megabytes % 1 === 0 ? megabytes.toFixed(0) : megabytes.toFixed(2);

  // Tarkistetaan, onko tulos 0.00 ja palautetaan 0.01
  return `${formattedSize === '0.00' ? '0.01' : formattedSize} MB`;
}

const ProjectNoteHeader = ({ note, closeNote, saveNoteManual, updateNoteTitle, noteSize, maxNoteSize, saved, saving, participantsText, showParticipants, setShowParticipants, canDeleteParticipants, canDeleteNote }) => {
  const { locale } = useTranslate();
  const [isEditing, setIsEditing] = useState(false); // Muokkaustila
  const [title, setTitle] = useState(note?.Otsikko); // Otsikon tila
  const [saveTextHover, setSaveTextHover] = useState(false);

  useEffect(() => {
    if (!note?.MuistioNro) return
    setTitle(note?.Otsikko)
  }, [note]);

  // Tallenna muutos
  const handleSave = async () => {
    setIsEditing(false); // Poistu muokkaustilasta
    if (title !== note?.Otsikko) {
      // Tee API-kutsu tallentaaksesi muutos tietokantaan
      updateNoteTitle(title);
    }
  };

  // Tallenna, kun kenttä menettää fokuksen (onBlur)
  const handleBlur = () => {
    handleSave();
  };

  // Päivitä tila, kun käyttäjä tekee muutoksia
  const handleChange = (e) => {
    setTitle(e.target.value);
  };

  return (
    <div className="project-note-header">
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p className="project-note-header-date font-dm-medium">{getParsedDate(note?.Paiva)}</p>
          <Tooltip title={locale.note_size} color={"#353535"}>
            <p className={`project-note-header-size ${noteSize >= maxNoteSize ? 'failed' : ''} font-dm-medium`}>{formatSizeInMB(noteSize)} / {formatSizeInMB(maxNoteSize)}</p>
          </Tooltip>

          <div
            onMouseEnter={() => setSaveTextHover(true)}
            onMouseLeave={() => setSaveTextHover(false)}
          >
            <p
              className={`project-note-header-size ${(!saved && !saveTextHover) ? 'failed' : ''} clickable font-dm-medium`}
              onClick={saveNoteManual}
            >
              {saving ? (
                <LoadingItem color="black" />
              ) : (
                <>
                  {saveTextHover ? (
                    <>
                      <SaveIcon /> {/* Näytetään hoverin aikana */}
                      {locale.save}
                    </>
                  ) : saved ? (
                    <>
                      <SavedIcon /> {/* Näytetään kun sisältö on tallennettu */}
                      {locale.note_saved}
                    </>
                  ) : (
                    <>
                      <UnSavedIcon /> {/* Näytetään jos sisältö ei ole tallennettu */}
                      {locale.note_unsaved}
                    </>
                  )}
                </>
              )}
            </p>
          </div>



        </div>

        {isEditing ? (
          <input
            type="text"
            className="project-note-header-title font-dm-bold"
            value={title}
            onChange={handleChange}
            onBlur={handleBlur} // Kun menettää fokuksen, tallennetaan
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSave(); // Tallennetaan, kun painetaan Enter
            }}
            autoFocus
          />
        ) : (
          <p
            className="project-note-header-title font-dm-bold"
            onClick={() => setIsEditing(true)} // Muokkaustilaan klikatessa
          >
            {title}
          </p>
        )}
      </div>


      <div className="project-note-header-right">
{/*         <CheckSquareOutlined
          className={`project-note-header-icon disabled`}
          onClick={() => { }}
        />
 */}
        <UsergroupAddOutlined
          className={`project-note-header-icon ${showParticipants ? 'disabled' : ''}`}
          onClick={() => {
            if (participantsText.length > 0 && showParticipants) {
              canDeleteParticipants()
            } else {
              setShowParticipants(!showParticipants)
            }
          }}
        />

{/*         <UploadOutlined
          className={`project-note-header-icon disabled`}
          onClick={() => { }}
        /> */}

        <DeleteOutlined
          className={`project-note-header-icon`}
          onClick={canDeleteNote}
        />

        <CloseOutlined
          className={`project-note-header-close ${saving ? 'failed' : ''}`}
          onClick={closeNote}
        />
      </div>

    </div>
  );
};



const ProjectNoteParticipants = ({ note, participantsText, setParticipantsText, showParticipants, setShowParticipants, updateNoteParticipants }) => {

  // Tallenna, kun kenttä menettää fokuksen (onBlur)
  const handleBlur = () => {
    if (participantsText !== note?.Osallistujat) {
      updateNoteParticipants(participantsText);
    }
  };

  return (<>
    {showParticipants ?
      <div className="project-note-participants">
        <p className="project-note-participants-header font-dm-bold">Osallistujat</p>

        <TextArea
          autoSize={{
            minRows: 3
          }}
          onBlur={handleBlur}
          style={{
            border: 0,
            paddingLeft: 0
          }}
          value={participantsText}
          onChange={(e) => {
            setParticipantsText(e.target.value)
            console.log("onChange", e.target.value)
          }} />

      </div>
      : <></>}
  </>)
}





export const ProjectNote = ({ }) => {
  const { locale } = useTranslate();
  let { id, noteId } = useParams();
  const navigate = useNavigate();

  const envConfig = getEnvSettings();
  const [isLoading, setisLoading] = useState(true);

  const [note, setNote] = useState({});

  const maxNoteSize = 2 * 1024 * 1024; // 2 MB
  const [noteSize, setNoteSize] = useState(0); // Kokonausmuistion koko

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(true);

  const richEditorRef = useRef(null);

  const [showParticipants, setShowParticipants] = useState(false);
  const [participantsText, setParticipantsText] = useState("");



  //NoteInitial
  useEffect(() => {
    if (note?.Osallistujat && note?.Osallistujat?.length > 0) {
      setShowParticipants(true)
      setParticipantsText(note.Osallistujat)
    }
  }, [note]);

  useEffect(() => {
    if (noteSize >= maxNoteSize) {
      notification.open({
        message: locale.note_size_error,
        placement: 'top',
        type: 'error'
      });
    }
  }, [noteSize]);


  const saveNoteManual = async () => {
    if (richEditorRef.current) {
      try {
        await richEditorRef.current.saveNote();
      } catch (error) {
        console.log("Tallennus epäonnistui:", error);
      }
    }
  };




  const closeNoteFunc = () => {
    navigate(-1)
  }




  const getNote = () => {
    setisLoading(true);

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getProjectNote`,
      data: {
        projectId: id,
        noteId: noteId,
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        setNote(response.data.data)
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  useEffect(() => {
    console.log('getNote')
    getNote()
  }, []);


  const canDeleteNote = async () => {
    console.log('canDeleteNote')

    Modal.confirm({
      title: locale.note_delete_question,
      okText: locale.yes,
      cancelText: locale.no,
      onOk: deleteNote
    });
  }

  const canDeleteParticipants = async () => {
    console.log('canDeleteNote')

    Modal.confirm({
      title: locale.note_delete_participants_question,
      okText: locale.yes,
      cancelText: locale.no,
      onOk: () => {
        updateNoteParticipants(null, true)
        setShowParticipants(false)
        setParticipantsText("")
      }
    });
  }

  const deleteNote = async () => {
    console.log('deleteNote')

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}deleteNote`,
      data: {
        noteId: noteId,
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        if (response?.data?.status) {
          notification.open({
            message: locale.note_deleted,
            placement: 'top',
            type: 'success'
          });
          navigate(-1)
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const updateNoteTitle = (title) => {
    console.log('updateNoteTitle', title)

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateProjectNote`,
      data: {
        noteId: noteId,
        type: "title",
        value: title
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        if (response?.data?.status) {
          notification.open({
            message: locale.note_header_updated,
            placement: 'top',
            type: 'success'
          });
        } else {
          notification.open({
            message: locale.note_header_update_failed,
            placement: 'top',
            type: 'error'
          });
        }

      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };


  const updateNoteParticipants = (partText, clear = false) => {
    console.log('updateNoteParticipants', partText)

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateProjectNote`,
      data: {
        noteId: noteId,
        type: "participants",
        value: partText
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        if (response?.data?.status) {

          if (!clear) {
            notification.open({
              message: locale.note_participants_updated,
              placement: 'top',
              type: 'success'
            });
          }

        } else {
          notification.open({
            message: locale.note_participants_update_failed,
            placement: 'top',
            type: 'error'
          });
        }

      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const updateNoteData = async (jsonData, isAuto) => {
    console.log('isAuto', isAuto)
    if (noteSize >= maxNoteSize) {
      notification.open({
        message: locale.note_size_error2,
        placement: 'top',
        type: 'error'
      });
      throw new Error(locale.note_size_error2); // Heitä virhe, jos muistiinpano on liian suuri
    }

    setSaving(true)

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateProjectNote`,
      data: {
        noteId: noteId,
        type: "data",
        value: jsonData
      },
    };

    console.log("UPP2", req_config);

    try {
      const response = await apiSecure(req_config);

      if (response?.data?.status) {
        setSaved(true)

        if (!isAuto) {
          notification.open({
            message: `${locale.note_updated}`,
            placement: 'top',
            type: 'success'
          });
        }

      } else {
        notification.open({
          message: `${locale.note_update_failed}`,
          placement: 'top',
          type: 'error'
        });
        throw new Error(`${locale.note_update_failed}`);
      }
    } catch (error) {
      console.log("Error:", error);
      notification.open({
        message: locale.note_save_failed,
        description: error.message,
        placement: 'top',
        type: 'error'
      });
      throw error; // Heitä virhe eteenpäin käsiteltäväksi muissa funktioissa
    } finally {
      setSaving(false)
    }
  };


  return (<div className={`projectpage-content`}>
    <div className="project-info">


      {note?.MuistioNro ?
        <ProjectNoteHeader
          note={note}
          noteSize={noteSize}
          maxNoteSize={maxNoteSize}
          closeNote={closeNoteFunc}
          saveNoteManual={saveNoteManual}
          updateNoteTitle={updateNoteTitle}
          saved={saved}
          saving={saving}
          showParticipants={showParticipants}
          setShowParticipants={setShowParticipants}
          canDeleteParticipants={canDeleteParticipants}
          participantsText={participantsText}
          canDeleteNote={canDeleteNote}
        />
        : <></>}

      <div className="project-note">
        {!isLoading ? <>

          {note?.MuistioNro ? <>


            <ProjectNoteParticipants
              note={note}
              participantsText={participantsText}
              setParticipantsText={setParticipantsText}
              showParticipants={showParticipants}
              setShowParticipants={setShowParticipants}
              updateNoteParticipants={updateNoteParticipants}
            />




            <RichEditor
              ref={richEditorRef}
              note={note}
              noteSize={noteSize}
              setNoteSize={setNoteSize}
              maxNoteSize={maxNoteSize}
              updateNoteData={updateNoteData}
              setSaved={setSaved}
              saved={saved}
            />


          </>
            : <p className="font-dm-medium">Muistiota ei löydy</p>}


        </> : <LoadingItem color={"black"} />}
      </div>

    </div>
  </div>

  );
};
