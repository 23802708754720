import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Modal from "react-modal";

import { Input } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../components/Translate";
import { apiSecure } from "../../components/api";
import { getEnvSettings } from "../../config/config";
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const ModalHeader = ({ closeModal, header, user }) => {
  return (
    <div>
      <div className="proj-modalHeaderRow">
        <p className="proj-modalHeader font-dm-bold">{header}</p>
        <CloseOutlined
          className="proj-modalHeaderClose"
          onClick={() => closeModal()}
        />
      </div>
      <p className="proj-modalHeaderUser font-dm-medium">{user}</p>
    </div>
  );
};

const SaveBtn = ({ onClick }) => {
  const { locale } = useTranslate();

  return (
    <div className="proj-saveButtonCont">
      <button className="proj-saveButton font-dm-regular" onClick={onClick}>
        {locale.save}
      </button>
    </div>
  );
};


const ModalTextItem = ({ label, value, setValue, disabled = false,canLock=false, locked, setLocked}) => {
  const { locale } = useTranslate();

  return (<div>
    <p className="font-dm-medium label">{label}</p>
    <Input
      value={value}
      disabled={locked || disabled}
      onChange={(e) => {
        setValue(e.target.value)
        console.log("onChange", e.target.value)
      }}
      addonAfter={canLock ? <>{locked ? <LockOutlined onClick={()=>{setLocked(false)}} /> : <UnlockOutlined onClick={()=>{setLocked(true)}} />}</> : null}
    />
  </div>
  );
};

const ModalTextAreaItem = ({ label, value, setValue, disabled = false }) => {
  const { locale } = useTranslate();

  return (<div>
    <p className="font-dm-medium label">{label}</p>
    <TextArea rows={4} value={value}
      disabled={disabled}
      onChange={(e) => {
        setValue(e.target.value)
        console.log("onChange", e.target.value)
      }} />
  </div>
  );
};

const formatNull = (data) => {
  return data ? data : null
};

export const EditContactModal = ({ isOpen, closeModal, projectId, data, refreshData }) => {
  console.log("EditContactModal data", data);
  const [role, setRole] = useState(null);
  const [name, setName] = useState(null);
  const [company, setCompany] = useState(null);
  const [phone1, setPhone1] = useState(null);
  const [phone2, setPhone2] = useState(null);
  const [mail, setMail] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [otherInfo, setOtherInfo] = useState(null);

  const [nameLocked, setNameLocked] = useState(true);
  const [companyLocked, setCompanyLocked] = useState(true);

  const { locale } = useTranslate();
  const envConfig = getEnvSettings();




  useEffect(() => {
    console.log("isOpen updated", isOpen);

    if (isOpen) {
      setRole(data?.OnkoTilaaja ? "Tilaaja" : data.Toimenkuva)
      setName(data.Henkilo)
      setCompany(data.Yritys)
      setPhone1(data.GSM)
      setPhone2(data.Puhelin)
      setMail(data.Sahkoposti)
      setAddress1(data.Lahiosoite)
      setAddress2(data.Postiosoite)
      setOtherInfo(data.Muu_info)
    } else {
      setRole(null)
      setName(null)
      setCompany(null)
      setPhone1(null)
      setPhone2(null)
      setMail(null)
      setAddress1(null)
      setAddress2(null)
      setOtherInfo(null)
      setNameLocked(true)
      setCompanyLocked(true)
    }
  }, [isOpen]);




  const saveData = () => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateContact`,
      data: {
        projectId: projectId,
        contactId: data.YhteysNRO,
        data: {
          isOrderer: data?.OnkoTilaaja,
          role: data?.OnkoTilaaja ? null : role,
          name: formatNull(name),
          company: formatNull(company),
          phone1: formatNull(phone1),
          phone2: formatNull(phone2),
          mail: formatNull(mail),
          address1: formatNull(address1),
          address2: formatNull(address2),
          otherInfo: formatNull(otherInfo),
        },
      },
    };
    console.log("updateContact", req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        refreshData()
      })
      .catch((error) => {
        console.log("Error:", error);
      });

    closeModal()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        className="workmodalBase"
        style={{
          overlay: {
            zIndex: 100,
            backgroundColor: "rgba(70, 70, 70, 0.5)",
          },
        }}
        ariaHideApp={false}
      >
        <div className="proj-modalContainer" >
          <div className="proj-modal" style={{ minWidth: '500px' }}>
            <ModalHeader
              closeModal={() => closeModal()}
              header={"Muokkaa yhteystietoa"}
            />


            {projectId ?
              <ModalTextItem
                value={role}
                setValue={setRole}
                label={"Rooli"}
                disabled={data?.OnkoTilaaja ? true : false}
              />
              : <></>}

            <ModalTextItem
              value={name}
              setValue={setName}
              label={"Nimi"}
              canLock={true}
              locked={nameLocked}
              setLocked={setNameLocked}
            />

            <ModalTextItem
              value={company}
              setValue={setCompany}
              label={"Yritys"}
              canLock={true}
              locked={companyLocked}
              setLocked={setCompanyLocked}
            />

            <ModalTextItem
              value={phone1}
              setValue={setPhone1}
              label={"Puhelin"}
            />
            <ModalTextItem
              value={phone2}
              setValue={setPhone2}
              label={""}
            />

            <ModalTextItem
              value={mail}
              setValue={setMail}
              label={"Sähköposti"}
            />

            <ModalTextItem
              value={address1}
              setValue={setAddress1}
              label={"Osoite"}
            />

            <ModalTextItem
              value={address2}
              setValue={setAddress2}
              label={""}
            />

            <ModalTextAreaItem
              value={otherInfo}
              setValue={setOtherInfo}
              label={"Huomautuksia"}
            />

            <SaveBtn onClick={() => {
              saveData()
            }
            } />

            {!data?.OnkoTilaaja && projectId ?
              <div className="proj-cancelButtonCont">
                <button className="proj-cancelButton font-dm-regular" onClick={() => {
                  closeModal()
                }}>
                  {"Poista yhteystieto projektilta"}
                </button>
              </div>
              : null}

          </div>
        </div>
      </Modal>
    </>
  );
};
